import React from 'react'

import get from 'lodash/get';
import Input from '~/components/Input';

const PriceLevelRow = {
  Header: '₽',
  id: 'priceLevel',
  width: 50,
  className: 'priceRow',
  Filter: ({ onChange }) => <Input onChange={onChange} type='number' min={1} />,
  filterMethod: (filter, row) => {
    return row.priceLevel ? row.priceLevel == filter.value : false
  },
  accessor: d => get(d, 'priceLevel', ''),
  Cell: row => <Presentation id={get(row.original, 'sys.id')}>{row.value}</Presentation>
};

export default PriceLevelRow;


const Presentation = ({ id, children }) => {
  const url = `https://hinter.sanatorex.ru/${id}`;
  return (
    <a
      href='#'
      disabled={!id}
      className='sanatorium_info_button'
      onClick={
        (e) => {
          window.open(url, 'Подсказка по санаторию', 'width=1000,height=900,left=900,top=200,toolbar=0,status=1'),
          e.preventDefault()
        }
      }
    >
      {children}
  </a>
  )
};