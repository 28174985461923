import React from 'react'

import classnames from 'classnames';
import omit from 'lodash/omit';
import './index.css';

const Input = (props) => {
  const propsOut = omit(props, ['className', 'onChange'])
  return (
    <input {...propsOut} onChange={(e) => props.onChange(e.target.value)} className={classnames('textInput', props.className)} autoComplete='nope' />
  )
}

export default Input;