import React, { Component } from 'react';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '~/helpers/environment';

import { getMedProfiles } from '~/helpers/contentful';
import classnames from 'classnames'

import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import without from 'lodash/without';
import concat from 'lodash/concat';

import Icon from '~/components/Icon';
import Tooltip from '~/components/Tooltip';

const MedProfilesRow = {
  Header: 'Лечебные профили',
  id: 'mainProfile',
  filterMethod: (filter, row) =>
    intersection(row.mainProfile, filter.value).length === filter.value.length,
  Filter: ({ filter, onChange }) => (
    <MedProfilesFilter
      onClick={(id) => onChange(
        includes(get(filter, 'value', []), id)
          ? without(get(filter, 'value', []), id)
          : concat(get(filter, 'value', []), id)
      )}
      filter={get(filter, 'value', [])}
    />
  ),
  accessor: d => {
    const mainMedProfiles = map(get(d, 'mainMedProfiles', []), 'id');
    const extraMedProfiles = map(get(d, 'extraMedProfiles', []), 'id');
    const medProfiles = concat(mainMedProfiles, extraMedProfiles);
    return medProfiles;
  },
  Cell: row => (
    <MedProfiles
      id={row.original.sys.id}
      info={get(row.original, 'specialize')}
      main={get(row.original, 'mainMedProfiles', [])}
      extra={get(row.original, 'extraMedProfiles', [])}
      medicalCard={get(row.original, 'conditions.medicalCard')}
    />
  )
};

export default MedProfilesRow;

const MedProfiles = ({ extra, main, info, id, medicalCard }) => (
  <div className='medProfile' data-tip data-for={`medProfile-${id}`}>
    <div className='medProfile__main'>
      {map(main, (e, i) => <Icon key={i} src={e.icon.file.url} title={e.title} />)}
    </div>
    <div className='medProfile__extra'>
      {map(extra, (e, i) => <Icon key={i} src={e.icon.file.url} title={e.title} />)}
    </div>
    {info && <Tooltip id={`medProfile-${id}`} multiline className='tooltip'>
      {info}
      {medicalCard ? <p><b>Санаторно-курортная карта</b><br/>{medicalCard}</p> : ''}
    </Tooltip>}
  </div>
);

const MedProfilesFilter = ({ filter, onClick }) => (
  <QueryRenderer
    environment={environment}
    query={query}
    variables={{}}
    render={({error, props}) => {
      if (error) {
        return <div>Error!</div>;
      }
      if (!props) {
        return <div>Loading...</div>;
      }

      return (
        <div className='medProfile'>
          {map(props.medicalProfiles, (e, i) =>
            <Icon
              key={i}
              className={classnames('filter', { active: includes(filter, e.id) })}
              src={e.icon.file.url}
              onClick={() => onClick(e.id)}
              title={e.title}
            />)}
        </div>
      )
    }}
  />
);

const query = graphql`
  query MedProfilesQuery {
    medicalProfiles {
      id,
      title,
      slug,
      icon {
        id,
        title,
        file {
          url
        }
      }
    }
  }
`;