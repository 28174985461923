import React from 'react'

import get from 'lodash/get';
import map from 'lodash/map';
import Tooltip from '~/components/Tooltip';
import Icon from '~/components/Icon';
import Input from '~/components/Input';

const CityRow = {
  Header: 'Город',
  id: 'city',
  width: 160,
  Filter: ({ onChange }) => <Input onChange={onChange} type='text' />,
  accessor: d => get(d, 'city'),
  Cell: row => <City locations={get(row.original, 'location', [])} title={row.value} id={get(row.original, 'sys.id', '')} />
};

export default CityRow;

const City = ({ onClick, title, locations, id }) => (
  <div className='city__td' onClick={onClick}>
    {title}
    {locations && locations[0] && <i data-tip data-for={`location-${id}`}><Icon type='info' /></i>}
    {locations && locations[0] && <Tooltip id={`location-${id}`} multiline className='tooltip'><Locations>{locations}</Locations></Tooltip>}
  </div>
);

const Locations = ({ children }) => (
  <ul className='locations'>
    {map(children, (location, e) => <Location key={e} {...location} />)}
  </ul>
);

const Location = ({ name, distance, unit }) => {
  return (
    <li>{name}, {distance} {unit}</li>
  )
}