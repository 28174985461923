import React from 'react'

import REGIONS from '../rows/constants/Regions';

const Select = ({ onChange }) => (
  <Items onChange={onChange} >
    {REGIONS.regions.map((entry,key) => <Option {...entry} key={key} />)}
  </Items>
);

export default Select;

const Items = ({ children, onChange  }) => (
  <select id='select' onChange={(e) => onChange(e.target.value)} className='textInput'>
    <option label='Россия' />{children}
  </select>
);

const Option = ({ title }) => <option>{title}</option>;