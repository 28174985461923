import React from 'react'

import get from 'lodash/get';

import Icon from '~/components/Icon';
import Checkbox from '~/components/Checkbox';
import Bool from './widgets/Bool';

export const TypeFoodRow = {
  Header: <div className='sanatoriumLinks__food' />,
  id: 'serviceInfo',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.food : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => !!get(d, 'serviceInfo.food', ''),
  Cell: row => {
    const food = get(row.original, 'serviceInfo.food', '');
    return <Bool id={row.original.sys.id} type='food'>{food}</Bool>;
  }
};

export const AccommodationRow = {
  Header: <div className='sanatoriumLinks__home' />,
  id: 'accommodation',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.accommodation : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => !!get(d, 'infrastructure.accommodation', ''),
  Cell: row => {
    const accommodation = get(row.original, 'infrastructure.accommodation', '');
    return <Bool id={row.original.sys.id} type='accommodation'>{accommodation}</Bool>;
  }
};

export const TherapyRow = {
  Header: <div className='sanatoriumLinks__sleep' />,
  id: 'therapy',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.therapy : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'therapy', '') ? true : false,
  Cell: row => (
    <Bool id={row.original.sys.id}>{get(row.original, 'therapy', '')}</Bool>
  )
};

export const PoolRow = {
  Header: <div className='sanatoriumLinks__pool' />,
  id: 'pool',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.pool : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'infrastructure.pool', '') ? true : false,
  Cell: row => {
    const pool = get(row.original, 'infrastructure.pool', '');
    const aquapark = get(row.original, 'infrastructure.aquapark', '')
    return (
    <Bool id={row.original.sys.id} type='pool'>
      {pool
        ? <div>
          {pool}
          {aquapark
            ? <p><b>Аквапарк</b><br/> {aquapark}</p>
            : ''}
            </div>
        : ''}
    </Bool>
    );
  }
};

export const ParkingRow = {
  Header: <div className='sanatoriumLinks__park' />,
  id: 'parking',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.parking : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'infrastructure.parking', '') ? true : false,
  Cell: row => <Bool id={row.original.sys.id} type='parking'>{get(row.original, 'infrastructure.parking', '')}</Bool>
};

export const WaterRow = {
  Header: <div className='sanatoriumLinks__water' />,
  id: 'water',
  width: 50,
  className: 'boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.water : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'infrastructure.wellRoom', '') ? true : false,
  Cell: row => <Bool id={row.original.sys.id} type='water'>{get(row.original, 'infrastructure.wellRoom', '')}</Bool>
};

export const SpaRow = {
  Header: 'SPA',
  id: 'spa',
  width: 50,
  className: 'boolRow position__boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.spa : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'infrastructure.spa', '') ? true : false,
  Cell: row => <Bool id={row.original.sys.id} type='spa'>{get(row.original, 'infrastructure.spa', '')}</Bool>
};

export const BeachRow = {
  Header: 'Пляж',
  id: 'beach',
  width: 50,
  className: 'boolRow position__boolRow',
  filterMethod: (filter, row) => filter.value ? filter.value === row.beach : true,
  Filter: ({ onChange }) => <Checkbox onChange={onChange} />,
  accessor: d => get(d, 'infrastructure.beach', '') ? true : false,
  Cell: row => <Bool id={row.original.sys.id} type='beach'>{get(row.original, 'infrastructure.beach', '')}</Bool>
};