import React from 'react';

import get from 'lodash/get';
import map from 'lodash/map';

import Select from '~/components/Select';

import REGIONS from './constants/Regions';

const RegionRow = {
  Header: 'Регион',
  id: 'region',
  width: 120,
  className: 'regionRow',
  accessor: d => get(REGIONS.regions.find(region => region.cities.indexOf(d.city) != -1), 'title'),
  Filter: ({ onChange }) => <Select onChange={onChange} />,
};

export default RegionRow;