import React from 'react'

import get from 'lodash/get';

import ReactMarkdown from 'react-markdown';
import Tooltip from '~/components/Tooltip';
import Input from '~/components/Input';
import Icon from '~/components/Icon';

const ChildrenRow = {
  Header: <div className='sanatoriumLinks__children' />,
  id: 'children',
  className: 'boolRow',
  width: 60,
  filterMethod: (filter, row) => {
    const childrenAge = get(row._original, 'conditions.childrenAge', 'undefind');
    return childrenAge !== undefined ? childrenAge <= filter.value : false
  },
  Filter: ({ onChange }) => <Input onChange={onChange} type='number' />,
  accessor: d => get(d, 'conditions.childrenAge', ''),
  Cell: row => (
    <Children
      id={row.original.sys.id}
      old={row.value}
      conditions={get(row.original, 'conditions.children', '')}
      infrastructure={get(row.original, 'infrastructure.children', '')}
    />
  )
};

export default ChildrenRow;

const Children = ({ conditions, infrastructure, old, id }) => (
  <div data-tip data-for={`children-${id}`}>
    <div className='boolRow__value--true'>{old}</div>
    <Tooltip id={`children-${id}`} multiline className='tooltip'>
      {infrastructure}
      <ReactMarkdown source={conditions} />
    </Tooltip>
  </div>
);
