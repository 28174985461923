import React from 'react';

import get from 'lodash/get';
import join from 'lodash/join';

import Tooltip from '~/components/Tooltip';
import Icon from '~/components/Icon';
import Input from '~/components/Input';

const NameRow = {
  Header: 'Название',
  id: 'title',
  accessor: (d) => get(d, 'title'),
  Filter: ({ onChange }) => <Input onChange={onChange} type='text' />,
  Cell: (row) => (
    <SanatoriumRow
      title={row.value}
      info={get(row.original, 'announcement')}
      id={get(row.original, 'sys.id', '')}
      type={get(row.original, 'type', '')}
      site={get(row.original, 'contract.site')}
      brandSite={(get(row.original, 'settings.site', '') || '').replace(
        /https:\/\/(.+?)\.sanatorex\.ru/,
        'https://sanatorex.ru/hotels/$1'
      )}
      directly={get(row.original, 'contract.date', '')}
      cash={get(row.original, 'conditions.cash')}
      comission={get(row.original, 'contract.commission')}
      stars={get(row.original, 'stars')}
      phone={get(row.original, 'contract.phone')}
      alean={get(row.original, 'serviceInfo.alean')}
      delfin={get(row.original, 'serviceInfo.delfin')}
      profkurort={get(row.original, 'serviceInfo.profkurort')}
      hint={get(row.original, 'serviceInfo.hint')}
    />
  ),
};

export default NameRow;

const SanatoriumRow = ({
  delfin,
  profkurort,
  phone,
  title,
  type,
  hint,
  cash,
  directly,
  site,
  id,
  info,
  comission,
  brandSite,
  stars,
  alean,
}) => (
  <div className='sanatoriumTitle'>
    <div>
      <div className='sanatoriumType'>
        {type.toLowerCase()}
        &nbsp;{stars}*
      </div>
      <a
        href={brandSite ? `${brandSite}/rooms` : undefined}
        target='_blank'
        title='Сайт Санаторекса'
      >
        {title}
      </a>
      <a href={site} target='_blank' title='Официальный сайт'>
        <div className='sanatoriumLinks__link' />
      </a>
      <div className='sanatoriumLinks'>
        {alean && (
          <a
            href={`${alean}`}
            target='_blank'
            className='sanatoriumLinks__alean'
            title='Алеан'
          />
        )}
        {profkurort && (
          <a
            href={`${profkurort}`}
            target='_blank'
            className='sanatoriumLinks__profkurort'
            title='Профкурорт'
          />
        )}
        {delfin && (
          <a
            href={`${delfin}`}
            target='_blank'
            className='sanatoriumLinks__delfin'
            title='Дельфин'
          />
        )}
      </div>
    </div>
    <div className='sanatoriumInfo'>
      {hint ? (
        <i data-tip data-for={`hint-${id}`}>
          <div className='sanatoriumLinks__man' />
        </i>
      ) : (
        ''
      )}
      {directly ? (
        <i data-tip data-for={`directly-${id}`}>
          <div className='sanatoriumLinks__directly' />
        </i>
      ) : (
        ''
      )}
      {cash ? (
        <i data-tip='Наличными в кассу объекта'>
          <div className='sanatoriumLinks__cash' />
        </i>
      ) : (
        ''
      )}
      {info ? (
        <i data-tip data-for={`info-${id}`}>
          <div className='sanatoriumLinks__info' />
        </i>
      ) : (
        ''
      )}
      {phone ? <a href={`tel: ${phone}`} className='phone' /> : ''}
    </div>
    {hint && (
      <Tooltip id={`hint-${id}`} multiline className='tooltip'>
        {hint}
      </Tooltip>
    )}
    {cash && <Tooltip className='tooltip' />}
    {directly && (
      <Tooltip id={`directly-${id}`} multiline className='tooltip'>
        Вознаграждение: {comission}%<br />
        Сотрудничаем с {directly}
      </Tooltip>
    )}
    {info && (
      <Tooltip id={`info-${id}`} multiline className='tooltip'>
        {info}
      </Tooltip>
    )}
  </div>
);
