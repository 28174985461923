import React from 'react'

import get from 'lodash/get';
import Input from '~/components/Input';

const StarsRow =  {
  Header: '*',
  id: 'stars',
  width: 50,
  Filter: ({ onChange }) => <Input onChange={onChange} type='number' /> ,
  accessor: d => get(d, 'stars'),
  Cell: row => <div>{row.value}<sup>*</sup></div>
}

export default StarsRow;