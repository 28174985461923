const REGIONS = {
  regions: [
    {
      title: 'КРД',
      cities: [
        'Сочи',
        'Анапа',
        'Геленджик',
        'Адлер',
        'Туапсе',
        'Архипо-Осиповка',
        'Витязево',
        'Дивноморское',
        'Уч-Дере',
        'Сукко',
        'Лоо',
        'Дедеркой',
        'Майский',
        'Горячий Ключ',
        'Новомихайловский',
        'Лазаревское'
      ]
    },
    {
      title: 'КМВ',
      cities: [
        'Кисловодск',
        'Ессентуки',
        'Пятигорск',
        'Железноводск',
        'Минеральные воды'
      ]
    },
    {
      title: 'КБР',
      cities: [
        'Нальчик'
      ]
    },
    {
      title: 'Алтай',
      cities: [
        'Белокуриха'
      ]
    },
     {
      title: 'КЧР',
      cities: [
        'Теберда'
      ]
    },
    {
      title: 'Карелия',
      cities: [
        'Кончезеро',
        ''
      ]
    },
    {
      title: 'Челябинск',   
      cities: [
        'Хомутинино',
        'Карагайский'
      ]
    }, 
    {
      title: 'СПБ',
      cities: [
        'Красное',
        'Сестрорецк',
        'Зеленогорск',
        'Рощино'
      ]
    },
    {
      title: 'Ростовская область',
      cities: [
        'Станко'
      ]
    },
    {
      title: 'Тверская область',
      cities: [
        'Красный Холм'
      ]
    },
    {
      title: 'Воронежская область',
      cities: [
        'Воронеж',
        'Лиски'
      ]
    },
    {
      title: 'Пермский край',
      cities: [
        'Усть-Качка'
      ]
    },
    {
      title: 'Чувашская республика',
      cities: [
        'Сюктерка'
      ]
    },
    {
      title: 'Нижнегородская область',
      cities: [
        'Аксентис'
      ]
    },
    {
      title: 'Ивановская область',
      cities: [
        'Оболсуново',
        'Дьячёво'
      ]
    },
    {
      title: 'Тюмень',
      cities: [
        'Тюмень',
        'Заводоуковск',
        'Ахманка',
        'Тюменский',
        'Новоникольское'
      ]
    },
    {
      title: 'Новосибирск',
      cities: [
        'Краснозерское',
        'Бердск',
        'Озеро-Карачи'
      ]
    },
    {
      title: 'Дагестан',   
      cities: [
        'Манас'
      ]
    },
    {
      title: 'Татарстан',
      cities: [
        'Ижевка',
        'Васильево',
        'Петровский',
        'Казань',
        'Челны',
        'Карагай',
        'Красноусольск',
        'Танып',
        'Якты-Куль',
        'Янга-Тау',
        'Бакирово',
        'Лабинск'
      ]
    },
    {
      title: 'Новгородская область',
      cities: [
        'Старая Русса'
      ]
    },
    {
      title: 'Владимирская область',
      cities: [
        'Абельмана'
      ]
    },
    {
      title: 'Башкортостан',
      cities: [
        'Большеустьикинское',
        'Новые Казанчи',
        'Симеиз',
        'Янгантау',
        'Симеиз',
        'Юматово',
        'Абзелиловский',
        'Курорта'
      ]
    },
    {
      title: 'МО',
      cities: [
        'Подольск',
        'Ерино',
        'Барвиха',
        'Сергиев-Посад',
        'Удельная',
        'Юность',
        'Ново-Переделкино', 
        'Сосны',
        'Быково',
        'Валуево',
        'Балашиха',
        'Большая черная',
        'Рузский район',
        'Трехселище',
        'Звенигород',
        'Ташировское',
        'Старая Руза',
        'Толстяково',
        'Лунёво',
        'Пушкино'
      ]
    },
    {
      title: 'Абхазия',
      cities: [
        'Холодная речка'
      ]
    },
    {
      title: 'Адыгея',
      cities: [
        'Красный мост',
        'Даховская'
      ]
    },
    {
      title: 'Пенза',
      cities: [
        'Колышлей'
      ]
    },
    {
      title: 'Калининград',   
      cities: [
        'Светлогорск'
      ]
    },
    {
      title: 'Курганская область',
      cities: [
        'Шадринск'
      ]
    }, 
    {
      title: 'Крым',
      cities: [
        'Феодосия',
        'Алушта',
        'Ялта',
        'Саки',
        'Евпатория',
        'Керчь',
        'Кореиз',
        'Гаспра',
        'Песчаное',
        'Курпаты',
        'Форос'
      ]
    }
  ]
}

export default REGIONS;