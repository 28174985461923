import React from 'react'

import Tooltip from '~/components/Tooltip';

const BoolRow = ({ children, id, type }) => children ? (
  <div data-tip data-for={`${type}-${id}`}>
    <div className='boolRow__value--true'>+</div>
    {
      type && 
      <Tooltip id={`${type}-${id}`} multiline effect='solid' place='left' className='tooltip'>{children}</Tooltip>
    }
  </div>
) : '–';

export default BoolRow;