import React, { Component } from 'react';

import { graphql, QueryRenderer } from 'react-relay';

import 'react-table/react-table.css';
import './index.css';

import environment from '~/helpers/environment';

import ReactTable from 'react-table';

import { 
  CityRow, 
  NameRow,
  RegionRow, 
  PriceLevelRow, 
  MedProfilesRow,
  ChildrenRow
} from '~/components/rows'

import {
  TherapyRow,
  PoolRow,
  ParkingRow,
  WaterRow,
  AccommodationRow,
  TypeFoodRow,
  SpaRow,
  BeachRow
} from '~/components/rows/BoolRow';

const App = () => (
  <QueryRenderer
    environment={environment}
    query={query}
    variables={{}}
    render={({error, props}) => {
      if (error) {
        return <div>Error!</div>;
      }
      if (!props) {
        return <div>Loading...</div>;
      }

      return (
        <div>
          <ReactTable
            data={props.sanatoriums}
            defaultPageSize={16}
            defaultFilterMethod={
              (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                const value = filter.value || '';
                return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(value.toLowerCase()) : true
              }
            }
            filterable
            previousText='Назад'
            nextText='Вперед'
            loadingText='Загрузка...'
            noDataText='Ничего не найдено'
            pageText='Страница'
            ofText='из'
            rowsText='строк'
            className="-highlight"
            columns={[
              RegionRow,
              CityRow,
              NameRow,
              PriceLevelRow,
              MedProfilesRow,
              TypeFoodRow,
              AccommodationRow,
              TherapyRow,
              ChildrenRow,
              PoolRow,
              ParkingRow,
              WaterRow,
              SpaRow,
              BeachRow
            ]}
          />
        </div>
      );
    }}
  />
);

export default App;

const query = graphql`
  query AppQuery {
    sales {
      id,
      title
    },

    sanatoriums {
      id,
      sys {
        id
      },
      title,
      city,
      type,
      priceLevel,
      stars,
      announcement,
      specialize,
      meal,
      therapy,

      serviceInfo {
        food,
        alean,
        profkurort,
        delfin,
        hint
      },
      
      settings {
        id,
        site,
        phone,
        email,
        alean,
        partner,
      },
      
      infrastructure {
        id,
        wifi,
        children,
        pool,
        parking,
        wellRoom,
        rental,
        territory,
        foodCourt,
        building,
        accommodation,
        capacity,
        aquapark,
        line,
        beach,
        spa
      },
      
      mainMedProfiles {
        id,
        title,
        slug,
        icon {
          id,
          title,
          file {
            url
          }
        },
      },
      
      extraMedProfiles {
        id,
        title,
        slug,
        icon {
          id,
          title,
          file {
            url
          }
        },
      },
      
      conditions {
        id,
        title,
        accommodation,
        paymentMethod,
        cash,
        middleman,
        therapy,
        checkInTime,
        departureTime,
        additionallyPlace,
        childrenAge,
        medicalCard,
        children,
        animals,
        pets,
        bankCard,
        cancel,
      },
      
      contract {
        id,
        title,
        inn,
        legal_name,
        legal_address,
        middleman,
        date,
        commission,
        site,
        phone,
        email,
      },
      
      location {
        name,
        type,
        unit,
        distance
      },
      
    }
  }
`;
