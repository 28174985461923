import React from 'react'

import get from 'lodash/get';
import Input from '~/components/Input';

const MealRow = {
  Header: 'Питание',
  id: 'meal',
  width: 120,
  className: 'mealRow',
  Filter: ({ onChange }) => <Input onChange={onChange} type='text' />,
  accessor: d => get(d, 'meal', ''),
}

export default MealRow;