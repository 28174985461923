import React from 'react';
import ReactDOM from 'react-dom';

import App from '~/App';
import mixpanel from './helpers/mixpanel';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
  () => {
    mixpanel.track('View');
  }
);