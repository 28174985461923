import React from 'react'
import classnames from 'classnames'
import Tooltip from '~/components/Tooltip';

import '~/components/Icon/index.css';

const Icon = ({ src, title, type, className, data, onClick }) => {
  const icons = {
    cash: {
      src: 'https://image.flaticon.com/icons/svg/858/858151.svg',
      title: 'Наличными в кассу санатория'
    },
    directly: {
      src: 'https://image.flaticon.com/icons/svg/493/493808.svg',
      title: 'Работаем напрямую с'
    },
    pool: {
      src: 'https://image.flaticon.com/icons/svg/1180/1180797.svg',
      title: 'Бассейн'
    },
    parking: {
      src: 'https://image.flaticon.com/icons/svg/818/818383.svg',
      title: 'Парковка'
    },
    water: {
      src: 'https://image.flaticon.com/icons/svg/606/606797.svg',
      title: 'Бювет с минеральной водой'
    },
    children: {
      src: 'https://image.flaticon.com/icons/svg/105/105620.svg',
      title: 'Минимальный возраст с которого принимают детей'
    },
    checked: {
      src: 'https://image.flaticon.com/icons/svg/0/14.svg',
      title: ''
    },
    link: {
      src: '/static/link.png',
      title
    },
    info: {
      src: 'https://image.flaticon.com/icons/svg/151/151776.svg',
      title: ''
    },
    love: {
      src: 'https://image.flaticon.com/icons/svg/1077/1077035.svg',
      title: 'Взаимоотношения'
    },
    therapy: {
      src: 'https://image.flaticon.com/icons/svg/991/991943.svg',
      title: 'Без лечения'
    },
    middleman: {
      src: 'https://image.flaticon.com/icons/svg/912/912316.svg',
      title: 'Посредники'
    },
    typefood: {
      src: 'https://image.flaticon.com/icons/svg/263/263125.svg',
      title: 'Питание'
    },
    accommodation: {
      src: 'https://image.flaticon.com/icons/svg/15/15730.svg',
      title: 'О размещении'
    },
    reservation: {
      src: 'https://image.flaticon.com/icons/svg/2197/2197292.svg',
      title: 'Вариант бронирования'
    }
  };
  let t = title;
  if (type) {
    src = icons[type].src,
      title ? t = `${icons[type].title} ${title}` : t = icons[type].title
  }
  return (
    <img src={src} title={t} data={data} onClick={onClick} className={classnames('icon', className, {
      iconChecked: type == 'checked',
      iconLink: type == 'link',
      iconInfo: type == 'info'
    })} />
  )
};

export default Icon;
