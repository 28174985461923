import { createClient } from 'contentful';

const client = createClient({
  space: 'wpk8fz5zicme',
  accessToken: '3be3b7d6298ad1a969f7183cac600ea21a2d476b2eae37c1a5842cdec0af0048'
});

export const getSanatoriums = () => (
  client
    .getEntries({
      content_type: 'sanatoriums',
      limit: 1000,
      select: `
        fields.title,
        fields.type,
        fields.city,
        fields.contract,
        fields.mainMedProfiles,
        fields.extraMedProfiles,
        fields.conditions,
        fields.meal,
        fields.priceLevel,
        fields.infrastructure,
        fields.serviceInfo,
        fields.stars,
        fields.sales,
        fields.specialize,
        fields.announcement,
        fields.location,
        fields.therapy,
        sys.id,
        fields.settings
        `,
      include: 1  
    })
);

export const getMedProfiles = () => (
  client
    .getEntries({
      content_type: 'medicalProfile',
      limit: 1000,
      select: 'sys.id,fields.icon,fields.title',
      include: 1
    })
);