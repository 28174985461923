import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';

import request from 'superagent';

function fetchQuery(
  operation,
  variables
) {
  return request.post('https://graphql.sanatorex.ru/graphql')
    .set('Content-Type', 'application/json')
    .send({
      query: operation.text,
      variables
    })
    .then(response => response.body);
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
