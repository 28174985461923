/**
 * @flow
 * @relayHash 1ad5d9e8129af9c2266dbe6c0323b2b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppQueryVariables = {||};
export type AppQueryResponse = {|
  +sales: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
  |}>,
  +sanatoriums: ?$ReadOnlyArray<?{|
    +id: string,
    +sys: ?{|
      +id: ?string
    |},
    +title: ?string,
    +city: ?string,
    +type: ?string,
    +priceLevel: ?string,
    +stars: ?string,
    +announcement: ?string,
    +specialize: ?string,
    +meal: ?$ReadOnlyArray<?string>,
    +therapy: ?boolean,
    +serviceInfo: ?{|
      +food: ?string,
      +alean: ?string,
      +profkurort: ?string,
      +delfin: ?string,
      +hint: ?string,
    |},
    +settings: ?{|
      +id: string,
      +site: ?string,
      +phone: ?string,
      +email: ?string,
      +alean: ?string,
      +partner: ?boolean,
    |},
    +infrastructure: ?{|
      +id: string,
      +wifi: ?string,
      +children: ?string,
      +pool: ?string,
      +parking: ?string,
      +wellRoom: ?string,
      +rental: ?string,
      +territory: ?string,
      +foodCourt: ?string,
      +building: ?string,
      +accommodation: ?string,
      +capacity: ?number,
      +aquapark: ?string,
      +line: ?number,
      +beach: ?string,
      +spa: ?string,
    |},
    +mainMedProfiles: ?$ReadOnlyArray<?{|
      +id: string,
      +title: ?string,
      +slug: ?string,
      +icon: ?{|
        +id: string,
        +title: ?string,
        +file: ?{|
          +url: ?string
        |},
      |},
    |}>,
    +extraMedProfiles: ?$ReadOnlyArray<?{|
      +id: string,
      +title: ?string,
      +slug: ?string,
      +icon: ?{|
        +id: string,
        +title: ?string,
        +file: ?{|
          +url: ?string
        |},
      |},
    |}>,
    +conditions: ?{|
      +id: string,
      +title: ?string,
      +accommodation: ?string,
      +paymentMethod: ?$ReadOnlyArray<?string>,
      +cash: ?boolean,
      +middleman: ?$ReadOnlyArray<?string>,
      +therapy: ?boolean,
      +checkInTime: ?string,
      +departureTime: ?string,
      +additionallyPlace: ?string,
      +childrenAge: ?number,
      +medicalCard: ?string,
      +children: ?string,
      +animals: ?boolean,
      +pets: ?string,
      +bankCard: ?$ReadOnlyArray<?string>,
      +cancel: ?string,
    |},
    +contract: ?{|
      +id: string,
      +title: ?string,
      +inn: ?string,
      +legal_name: ?string,
      +legal_address: ?string,
      +middleman: ?$ReadOnlyArray<?string>,
      +date: ?string,
      +commission: ?string,
      +site: ?string,
      +phone: ?string,
      +email: ?string,
    |},
    +location: ?$ReadOnlyArray<?{|
      +name: ?string,
      +type: ?string,
      +unit: ?string,
      +distance: ?string,
    |}>,
  |}>,
|};
export type AppQuery = {|
  variables: AppQueryVariables,
  response: AppQueryResponse,
|};
*/


/*
query AppQuery {
  sales {
    id
    title
  }
  sanatoriums {
    id
    sys {
      id
    }
    title
    city
    type
    priceLevel
    stars
    announcement
    specialize
    meal
    therapy
    serviceInfo {
      food
      alean
      profkurort
      delfin
      hint
      id
    }
    settings {
      id
      site
      phone
      email
      alean
      partner
    }
    infrastructure {
      id
      wifi
      children
      pool
      parking
      wellRoom
      rental
      territory
      foodCourt
      building
      accommodation
      capacity
      aquapark
      line
      beach
      spa
    }
    mainMedProfiles {
      id
      title
      slug
      icon {
        id
        title
        file {
          url
        }
      }
    }
    extraMedProfiles {
      id
      title
      slug
      icon {
        id
        title
        file {
          url
        }
      }
    }
    conditions {
      id
      title
      accommodation
      paymentMethod
      cash
      middleman
      therapy
      checkInTime
      departureTime
      additionallyPlace
      childrenAge
      medicalCard
      children
      animals
      pets
      bankCard
      cancel
    }
    contract {
      id
      title
      inn
      legal_name
      legal_address
      middleman
      date
      commission
      site
      phone
      email
    }
    location {
      name
      type
      unit
      distance
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sales",
  "storageKey": null,
  "args": null,
  "concreteType": "Sale",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ]
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sys",
  "storageKey": null,
  "args": null,
  "concreteType": "Sys",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceLevel",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "stars",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "announcement",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "specialize",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "meal",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "therapy",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "food",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "alean",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "profkurort",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "delfin",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hint",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "site",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "settings",
  "storageKey": null,
  "args": null,
  "concreteType": "Settings",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v13/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "partner",
      "args": null,
      "storageKey": null
    }
  ]
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "children",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accommodation",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "infrastructure",
  "storageKey": null,
  "args": null,
  "concreteType": "Infrastructure",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wifi",
      "args": null,
      "storageKey": null
    },
    (v21/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pool",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "parking",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wellRoom",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rental",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "territory",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "foodCourt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "building",
      "args": null,
      "storageKey": null
    },
    (v22/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "capacity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "aquapark",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "line",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beach",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "spa",
      "args": null,
      "storageKey": null
    }
  ]
},
v24 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "slug",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "icon",
    "storageKey": null,
    "args": null,
    "concreteType": "Media",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "file",
        "storageKey": null,
        "args": null,
        "concreteType": "File",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "mainMedProfiles",
  "storageKey": null,
  "args": null,
  "concreteType": "MedicalProfile",
  "plural": true,
  "selections": (v24/*: any*/)
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "extraMedProfiles",
  "storageKey": null,
  "args": null,
  "concreteType": "MedicalProfile",
  "plural": true,
  "selections": (v24/*: any*/)
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "middleman",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "conditions",
  "storageKey": null,
  "args": null,
  "concreteType": "Conditions",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v22/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentMethod",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cash",
      "args": null,
      "storageKey": null
    },
    (v27/*: any*/),
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "checkInTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "departureTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "additionallyPlace",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "childrenAge",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "medicalCard",
      "args": null,
      "storageKey": null
    },
    (v21/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "animals",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pets",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bankCard",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cancel",
      "args": null,
      "storageKey": null
    }
  ]
},
v29 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contract",
  "storageKey": null,
  "args": null,
  "concreteType": "Contract",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "inn",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legal_name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legal_address",
      "args": null,
      "storageKey": null
    },
    (v27/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "commission",
      "args": null,
      "storageKey": null
    },
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/)
  ]
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "distance",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AppQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sanatoriums",
        "storageKey": null,
        "args": null,
        "concreteType": "Sanatorium",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "serviceInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "ServiceInfo",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          },
          (v20/*: any*/),
          (v23/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "location",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": true,
            "selections": [
              (v30/*: any*/),
              (v5/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AppQuery",
    "argumentDefinitions": [],
    "selections": [
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sanatoriums",
        "storageKey": null,
        "args": null,
        "concreteType": "Sanatorium",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "serviceInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "ServiceInfo",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v0/*: any*/)
            ]
          },
          (v20/*: any*/),
          (v23/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "location",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": true,
            "selections": [
              (v30/*: any*/),
              (v5/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v0/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AppQuery",
    "id": null,
    "text": "query AppQuery {\n  sales {\n    id\n    title\n  }\n  sanatoriums {\n    id\n    sys {\n      id\n    }\n    title\n    city\n    type\n    priceLevel\n    stars\n    announcement\n    specialize\n    meal\n    therapy\n    serviceInfo {\n      food\n      alean\n      profkurort\n      delfin\n      hint\n      id\n    }\n    settings {\n      id\n      site\n      phone\n      email\n      alean\n      partner\n    }\n    infrastructure {\n      id\n      wifi\n      children\n      pool\n      parking\n      wellRoom\n      rental\n      territory\n      foodCourt\n      building\n      accommodation\n      capacity\n      aquapark\n      line\n      beach\n      spa\n    }\n    mainMedProfiles {\n      id\n      title\n      slug\n      icon {\n        id\n        title\n        file {\n          url\n        }\n      }\n    }\n    extraMedProfiles {\n      id\n      title\n      slug\n      icon {\n        id\n        title\n        file {\n          url\n        }\n      }\n    }\n    conditions {\n      id\n      title\n      accommodation\n      paymentMethod\n      cash\n      middleman\n      therapy\n      checkInTime\n      departureTime\n      additionallyPlace\n      childrenAge\n      medicalCard\n      children\n      animals\n      pets\n      bankCard\n      cancel\n    }\n    contract {\n      id\n      title\n      inn\n      legal_name\n      legal_address\n      middleman\n      date\n      commission\n      site\n      phone\n      email\n    }\n    location {\n      name\n      type\n      unit\n      distance\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f31fb39e6dc6d0c6699ecd52e8f549b';
module.exports = node;
