/**
 * @flow
 * @relayHash 09aacfe25f50d05be8b8b85439679ecd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MedProfilesQueryVariables = {||};
export type MedProfilesQueryResponse = {|
  +medicalProfiles: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
    +slug: ?string,
    +icon: ?{|
      +id: string,
      +title: ?string,
      +file: ?{|
        +url: ?string
      |},
    |},
  |}>
|};
export type MedProfilesQuery = {|
  variables: MedProfilesQueryVariables,
  response: MedProfilesQueryResponse,
|};
*/


/*
query MedProfilesQuery {
  medicalProfiles {
    id
    title
    slug
    icon {
      id
      title
      file {
        url
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "medicalProfiles",
    "storageKey": null,
    "args": null,
    "concreteType": "MedicalProfile",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "slug",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "icon",
        "storageKey": null,
        "args": null,
        "concreteType": "Media",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "file",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MedProfilesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MedProfilesQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "MedProfilesQuery",
    "id": null,
    "text": "query MedProfilesQuery {\n  medicalProfiles {\n    id\n    title\n    slug\n    icon {\n      id\n      title\n      file {\n        url\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1936c21fbbdc22752297b5938f745c15';
module.exports = node;
